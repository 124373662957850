<template>
  <apexchart
    :height="250"
    type="bar"
    :options="options"
    :series="seriesInner"
  />
</template>

<script setup>
import { computed } from 'vue'
import clone from 'just-clone'

const props = defineProps({
  categories: Array,
  values: Array,
  label: String,
  series: Array,
})

const options = computed(() => {
  const options = clone(props.options || {})
  options.chart = options.chart || {}
  options.chart.id = options.chart.id || props.id || 'apexchart-bar-chart'
  options.xaxis = options.xaxis || {}
  options.xaxis.categories = options.xaxis.categories || props.categories || []
  options.fill = options.fill || {}
  options.fill.colors = options.fill.colors || '#9cc183'
  options.fill = options.fill || {}
  options.dataLabels = options.dataLabels || {}
  options.dataLabels.style = options.dataLabels.style || {}
  options.dataLabels.style.colors = options.dataLabels.style.colors || ['#202935']
  return options
})

const seriesInner = props.series || [
  {
    name: props.label,
    data: props.values,
  },
]
</script>
